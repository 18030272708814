@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background {
    z-index: -1000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #fafafa;
    overflow: hidden;
}

.background span {
    width: 50vmin;
    height: 50vmin;
    border-radius: 50vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 23;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

/* linkedin */
.background span:nth-child(1) {
    background: rgb(0,97,146);
    background: linear-gradient(90deg, rgba(0,97,146,1) 0%, rgba(255,255,255,1) 100%);
    filter: blur(100px);
    top: 0%;
    left: 25%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 0vw 0vh;
}
/* instagram */
.background span:nth-child(2) {
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    filter: blur(100px);
    top: 100%;
    left: 25%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 0vw 0vh;
}
/* snapchat */
.background span:nth-child(3) {
    background: rgb(255,252,0);
    background: linear-gradient(90deg, rgba(255,252,0,1) 0%, rgba(255,255,255,1) 100%);
    filter: blur(100px);
    top: 50%;
    left: 50%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 0vw 0vh;
}
/* tiktok */
.background span:nth-child(4) {
    background: rgb(37,244,238);
    background: linear-gradient(90deg, rgba(37,244,238,1) 0%, rgba(254,44,85,1) 51%, rgba(255,255,255,1) 100%);
    filter: blur(100px);
    top: 0%;
    left: 100%;
    animation-duration: 5s;
    animation-delay: -1s;
    transform-origin: 0vw 0vh;
}
/* youtube */
.background span:nth-child(5) {
    background: rgb(40,40,40);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,0,0,1) 100%);
    filter: blur(100px);
    top: 100%;
    left: 100%;
    animation-duration: 8s;
    animation-delay: -1s;
    transform-origin: 0vw 0vh;
}
.background span:nth-child(6) {
    background: rgb(29,161,242);
    background: linear-gradient(90deg, rgba(29,161,242,1) 0%, rgba(255,255,255,1) 100%);
    filter: blur(100px);
    top: 66%;
    left: 33%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 0vw 0vh;
}
